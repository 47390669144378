import { useEffect, useState } from 'react';
import { generatePath } from 'react-router';
import ModelTableWithRouterState from '../models/ModelTableWithRouterState';
import { Page, PageNav } from '../shared/Page';
import { WithPermission } from '../auth/AccessConditionals';
import * as StatusUtils from '../utils/Status';
import AddCaseFlowModal from './AddCaseFlowModal';
import {
  caseOrTaskMessage,
  latestCaseConfiguration,
  caseStatusesByCaseVersion,
  UiMessageKey
} from '../shared/types';
import { useAppConfiguration } from '../shared/contexts/AppConfiguration';
import { useParams } from 'react-router-dom';
import { useDynamicDocumentTitle } from '../shared/hooks/DocumentTitleHooks';
import { constructSearchDisplayColumns } from './SearchUtils';

const extractCaseRefFromPath = () => {
  console.log('extracting case ref from path');
  const elements = location.href.split('/');
  const last = elements[elements.length - 1];
  if (last.includes('?')) {
    console.log('Found a query string');
    return last.split('?')[0];
  } else {
    return last;
  }
};

const MyCasesListPage = (props) => {
  const { caseRef } = useParams();
  const [showAddCaseModal, setShowAddCaseModal] = useState(false);
  const appConfig = useAppConfiguration();
  const [caseConfig, setCaseConfig] = useState();
  const [timestamp, setTimestamp] = useState(new Date().getTime());
  const [apiEndpoint, setApiEndpoint] = useState();
  const [columns, setDisplayColumns] = useState([]);

  // attempt to grab hold of a case configuration, and if present use this to obtain configuration relating
  // to which columns from within the schema we display in the search results table. If we don't have a valid
  // case configuration, we don't show any case-schema specific columns
  useEffect(() => {
    if (caseRef && appConfig.loaded) {
      console.log(`building column model for ${caseRef}`);
      const caseConfig = latestCaseConfiguration(appConfig, caseRef);
      const statuses = caseStatusesByCaseVersion(
        appConfig,
        caseConfig.ref,
        caseConfig.version
      );
      if (caseConfig) {
        console.log('got correct config');
        setCaseConfig(caseConfig);
        setDisplayColumns(constructSearchDisplayColumns(caseConfig));
      }
    } else {
      setDisplayColumns(constructSearchDisplayColumns(undefined));
    }
  }, [caseRef, location, appConfig, timestamp]);

  useDynamicDocumentTitle(() => {
    return `Your ${caseOrTaskMessage(caseConfig, UiMessageKey.TitlePlural)}`;
  });

  useEffect(() => {
    if (appConfig.loaded) {
      console.log('setting case config');
      const config = latestCaseConfiguration(appConfig, caseRef);
      setCaseConfig(config);
      setApiEndpoint(`/api/profile/me/cases/${config.ref}`);
    }
  }, [appConfig, caseRef]);

  function openAddCaseModal() {
    setShowAddCaseModal(true);
  }

  function onAddCaseModalClose(value) {
    if (value) setTimestamp(new Date().getTime());
    setShowAddCaseModal(false);
  }

  return (
    <Page>
      {caseConfig && (
        <PageNav
          title={`Your ${caseOrTaskMessage(
            caseConfig,
            UiMessageKey.TitlePluralShort
          )}`}
        >
          <span className="mx-auto" />
          <WithPermission name="case:create">
            <button
              onClick={() => openAddCaseModal()}
              className="btn btn-sm btn-outline-secondary"
            >
              {`Add ${caseOrTaskMessage(
                caseConfig,
                UiMessageKey.TitleSingularShort
              )}`}
            </button>
          </WithPermission>
        </PageNav>
      )}

      {caseConfig && (
        <ModelTableWithRouterState
          caption="nav.cases.user"
          apiEndpoint={apiEndpoint}
          notFoundMessage={`No ${caseOrTaskMessage(
            caseConfig,
            UiMessageKey.TitlePluralShort
          )} found`}
          additionalQueryParams={{ t: timestamp }}
          columns={columns}
          itemHref={(item) => generatePath(`/cases/show/${item.id}`)}
          {...props}
        />
      )}

      {appConfig.loaded && caseConfig && showAddCaseModal && (
        <AddCaseFlowModal
          caseRef={caseConfig.ref}
          caseVersion={caseConfig.version}
          appConfig={appConfig}
          modalTitle={`Add ${caseOrTaskMessage(
            caseConfig,
            UiMessageKey.TitleSingularShort
          )}`}
          onModalClose={onAddCaseModalClose}
        />
      )}
    </Page>
  );
};

export default MyCasesListPage;
