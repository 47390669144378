import {
  CaseConfiguration,
  caseSearchStatuses,
  mapDisplaySpecifications
} from '../shared/types';
import {
  CaseDetailsSearchFields,
  ListColumnDefinition
} from './CaseDetailsSearchFields';
import { CaseStatusBadge } from './status/CaseStatus';
import { CasePriorityText } from './CasePriority';
import { toUkDateTimeString } from '../search/MemorableDate';
import { CommonDate } from '../shared/Dates';
import { QueryParameter } from '../models/QueryParameter';
import { AppConfiguration } from '../shared/contexts/AppConfiguration';

/**
 * This function takes the search column display specifications from within a given [CaseConfiguration],
 * and maps it through into series of [ListColumnDefinition]s, suitable to use by the weird ModelView table
 * component
 */
export const caseColumnDisplaySpecificationsToListColumnDefinitions = (
  caseConfig: CaseConfiguration
) => {
  if (caseConfig.meta.searchDisplaySpecifications) {
    return mapDisplaySpecifications(
      caseConfig,
      caseConfig.meta.searchDisplaySpecifications,
      (label, func) => {
        return new ListColumnDefinition(label, func);
      }
    );
  } else {
    return [];
  }
};

/**
 * Construct a finalised list of column specifications for the search table component. The columns specifications
 * will differ, based on whether we have an active case type or not
 */
export const constructSearchDisplayColumns = (
  caseConfig: CaseConfiguration | undefined
) => {
  if (caseConfig !== undefined) {
    const columns = [
      {
        label: 'ID',
        sortBy: 'id',
        fromModel: (item: { id: any }) => item.id
      },
      {
        label: 'Status',
        sortBy: 'status',
        fromModel: (item: {
          status: string;
          caseRef: string;
          caseVersion: number;
        }) => <CaseStatusBadge status={item.status} withLock={true} />
      },
      {
        label: 'Creator',
        fromModel: (item: { creator: { displayName: any } }) =>
          item.creator && item.creator.displayName
      },
      {
        label: 'Priority',
        sortBy: 'priority',
        fromModel: (item: { priority: any }) => (
          <CasePriorityText priority={item.priority} />
        )
      },
      {
        label: 'Created At',
        sortBy: 'createdAt',
        fromModel: (item: { createdAt: string }) =>
          toUkDateTimeString(item.createdAt)
      },
      {
        label: 'Updated At',
        sortBy: 'updatedAt',
        fromModel: (item: { updatedAt: string }) =>
          toUkDateTimeString(item.updatedAt)
      },
      {
        label: 'Next Review On',
        sortBy: 'nextReviewOn',
        fromModel: (item: { nextReviewOn: any }) => (
          // @ts-ignore
          <CommonDate date={item.nextReviewOn} fallback={'Not set'} />
        )
      }
    ];

    const caseDisplayColumns =
      caseColumnDisplaySpecificationsToListColumnDefinitions(caseConfig);
    if (caseDisplayColumns.length > 0) {
      columns.splice(2, 0, ...caseDisplayColumns);
    }
    return columns;
  } else {
    return [];
  }
};

export const constructSearchAdditionalQueryParams = (
  appConfiguration: AppConfiguration,
  caseConfig: CaseConfiguration,
  details: CaseDetailsSearchFields,
  location: Location,
  timestamp: number
) => {
  const searchParams = new URLSearchParams(location.search);
  return details.getQueryParamNames().reduce(
    (memo, queryParamName) => {
      // @ts-ignore
      memo[queryParamName] = searchParams.get(queryParamName);
      return memo;
    },
    {
      caseRef: caseConfig ? caseConfig.ref : null,
      status:
        searchParams.get(QueryParameter.caseStatus.key) ||
        caseSearchStatuses(appConfiguration, caseConfig).join(','),
      // creatorId may have comma-separated value|name pairs, e.g. 1|foo,2|bar => 1,2 for API call
      creatorId: searchParams
        .get(QueryParameter.caseCreatorId.key)
        ?.split(',')
        .map((it) => it.split('|')[0])
        .join(','),
      dueForReview: searchParams.get(QueryParameter.dueForReview.key),
      t: timestamp
    }
  );
};
