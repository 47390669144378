import React, { useEffect, useState } from 'react';
import { useAuthContext } from './AuthContextProvider';
import PropTypes from 'prop-types';

/**
 * TODO - this is a hot mess, needs reworking and made TS friendly
 * Very unclear how the actual permissions are supposed to work to the
 * casual observer, and as it stands would be a massive nightmare to port
 * to proper claims-based authorisation
 */

/**
 * Authorities given to the profile user, includes:
 * - scopes provided through Auth0 (we're not using those here)
 * - permissions provided through profile roles
 */
export const Authority = Object.freeze({
  'PERMISSION_edit:profile:all': 'PERMISSION_edit:profile:all',
  'PERMISSION_edit:case:own': 'PERMISSION_edit:case:own',
  'PERMISSION_read:case:all': 'PERMISSION_read:case:all',
  'PERMISSION_edit:case:all': 'PERMISSION_edit:case:all',
  'PERMISSION_sign:case:all': 'PERMISSION_sign:case:all',
  'PERMISSION_read:profile:all': 'PERMISSION_read:profile:all'
});

/**
 * Uses only "assigned" authorities, either through profile role permissions or Auth0 RBAC
 * Other default authorities used in JWT token scopes (ie: openid, profile, email) are not used in the API
 */
export const isRecognizedAuthority = (v) => {
  if (typeof v !== 'string') return false;
  return !!Authority[v] || !!v.match(/^ROLE_\S+/);
};

const profileAuthorities = (profile) => {
  const authorities = (profile && profile.authorities) || [];
  return authorities.filter((v) => isRecognizedAuthority(v));
};

const containsAny = (existing = [], candidates = []) => {
  if (!existing || !existing.length) return false;
  if (!candidates || !candidates.length) return false;
  return candidates.some((s) => existing.indexOf(s) > -1);
};

const containsAll = (existing = [], candidates = []) => {
  if (!existing || !existing.length) return false;
  if (!candidates || !candidates.length) return false;
  return candidates.every((s) => existing.indexOf(s) > -1);
};

const hasAnyPerm = (profile, authorities) =>
  containsAny(profileAuthorities(profile), authorities);
const hasAllPerm = (profile, authorities) =>
  containsAll(profileAuthorities(profile), authorities);

export const containsAssignedAuthority = (profile) => {
  return profileAuthorities(profile).length > 0;
};

const permissionPredicates = Object.freeze({
  none: (profile) => !containsAssignedAuthority(profile),

  'audit:read': (profile) =>
    hasAllPerm(profile, [
      Authority['PERMISSION_edit:case:all'],
      Authority['PERMISSION_edit:profile:all']
    ]),
  'form:preview': (profile) =>
    hasAnyPerm(profile, [Authority['PERMISSION_edit:case:all']]),

  'case:create': (profile) =>
    hasAnyPerm(profile, [
      Authority['PERMISSION_edit:case:all'],
      Authority['PERMISSION_edit:case:own']
    ]),
  'case:read': (profile, maybeInstance) =>
    hasAnyPerm(profile, [
      Authority['PERMISSION_edit:case:all'],
      Authority['PERMISSION_read:case:all']
    ]),
  'case:update': (profile, maybeInstance) =>
    hasAnyPerm(profile, [Authority['PERMISSION_edit:case:all']]) ||
    (hasAnyPerm(profile, [Authority['PERMISSION_edit:case:own']]) &&
      maybeInstance &&
      maybeInstance.creator &&
      maybeInstance.creator.id === profile.id),
  'case:clone': (profile, maybeInstance) =>
    hasAnyPerm(profile, [Authority['PERMISSION_edit:case:all']]) ||
    hasAllPerm(profile, [
      Authority['PERMISSION_edit:case:own'],
      Authority['PERMISSION_read:case:all']
    ]) ||
    (hasAnyPerm(profile, [Authority['PERMISSION_edit:case:own']]) &&
      maybeInstance &&
      maybeInstance.creator &&
      maybeInstance.creator.id === profile.id),
  'case:delete': () => false,

  get 'task:create'() {
    return this['case:update'];
  },
  // TODO 'task:read' should be accessible by watchers as well
  get 'task:read'() {
    return (profile, maybeInstance) =>
      this['case:read'](profile) ||
      (maybeInstance &&
        maybeInstance.assignee &&
        maybeInstance.assignee.id === profile.id);
  },
  get 'task:update'() {
    return (profile, maybeInstance) =>
      this['case:update'](profile) ||
      (maybeInstance &&
        maybeInstance.assignee &&
        maybeInstance.assignee.id === profile.id);
  },
  'task:delete': () => false,

  'task:watcher:update': (profile, maybeInstance) =>
    hasAnyPerm(profile, [
      Authority['PERMISSION_edit:case:all'],
      Authority['PERMISSION_edit:profile:all'],
      Authority['PERMISSION_read:profile:all']
    ]),
  'task:watcher:delete': (profile, maybeInstance) =>
    hasAnyPerm(profile, [
      Authority['PERMISSION_edit:case:all'],
      Authority['PERMISSION_edit:profile:all']
    ]),

  'comment:delete': (profile, maybeInstance) =>
    hasAnyPerm(profile, [Authority['PERMISSION_edit:case:all']]) ||
    (maybeInstance &&
      maybeInstance.owner &&
      maybeInstance.owner.id === profile.id),

  'profile:create': (profile) =>
    hasAnyPerm(profile, [Authority['PERMISSION_edit:profile:all']]),
  'profile:read': (profile, maybeInstance) =>
    hasAnyPerm(profile, [
      Authority['PERMISSION_edit:profile:all'],
      Authority['PERMISSION_edit:case:all'],
      Authority['PERMISSION_edit:case:own']
    ]),
  'profile:update': (profile, maybeInstance) =>
    hasAnyPerm(profile, [Authority['PERMISSION_edit:profile:all']]) ||
    (maybeInstance && maybeInstance.id === profile.id),
  'profile:delete': (profile, maybeInstance) =>
    hasAnyPerm(profile, [Authority['PERMISSION_edit:profile:all']]),
  'profile:revoke': (profile, maybeInstance) =>
    hasAnyPerm(profile, [Authority['PERMISSION_edit:profile:all']]),

  // no idea why there is a redirection through a lookup object in original variants of this
  // these two predicates are identical at the moment, although they could be different as required
  'case:reporter': (profile, maybeInstance) =>
    hasAnyPerm(profile, [
      'ROLE_admin',
      'ROLE_case_reporter',
      'PERMISSION_report:case:all'
    ]),
  'case:exporter': (profile, maybeInstance) =>
    hasAnyPerm(profile, [
      'ROLE_admin',
      'ROLE_case_reporter',
      'PERMISSION_report:case:all'
    ]),

  // permission check looking specifically for the admin role
  'role:admin': (profile, maybeInstance) => hasAnyPerm(profile, ['ROLE_admin']),
  'role:superadmin': (profile, maybeInstance) =>
    hasAnyPerm(profile, ['ROLE_superadmin'])
});

export const WithPermission = ({
  name,
  instance = null,
  inverse = false,
  children
}) => {
  console.log(`check permission ${name}`);
  const [hasAccess, setHasAccess] = useState(false);
  const { loading, isAuthenticated, profile } = useAuthContext();

  useEffect(() => {
    if (loading || !isAuthenticated || !profile) {
      setHasAccess(false);
    } else {
      const predicate = name && permissionPredicates[name];
      if (predicate) {
        console.log(profile);
        const predicateResult = predicate(profile, instance);
        setHasAccess(inverse === true ? !predicateResult : predicateResult);
      } else {
        setHasAccess(false);
      }
    }
  }, [loading, isAuthenticated, profile, name, instance, inverse]);

  return <>{hasAccess && children}</>;
};
WithPermission.propTypes = {
  name: PropTypes.oneOf(Object.keys(permissionPredicates)),
  instance: PropTypes.object,
  inverse: PropTypes.bool
};

export const WithAnyPermission = ({
  name = undefined,
  instance = null,
  inverse = false,
  children
}) => {
  const [hasAccess, setHasAccess] = useState(false);
  const { loading, isAuthenticated, profile } = useAuthContext();

  useEffect(() => {
    if (loading || !isAuthenticated || !profile) {
      setHasAccess(false);
    } else {
      let access = false;
      const anyPerm = name.split(',');
      for (let index = 0; index < anyPerm.length; index++) {
        const permName = anyPerm[index].trim();
        const predicate = permName && permissionPredicates[permName];
        if (predicate) {
          const predicateResult = predicate(profile, instance);
          access = inverse === true ? !predicateResult : predicateResult;
          if (access) break;
        }
      }
      setHasAccess(access);
    }
  }, [loading, isAuthenticated, profile, name, instance, inverse]);

  return <>{hasAccess && children}</>;
};
WithAnyPermission.propTypes = {
  name: PropTypes.string,
  instance: PropTypes.object,
  inverse: PropTypes.bool
};

export const IsNotLoggedIn = ({ children }) => {
  const { loading, isAuthenticated } = useAuthContext();
  return <>{!loading && !isAuthenticated && children}</>;
};
