import React, { useEffect, useState } from 'react';
import { generatePath } from 'react-router';
import ModelTableWithRouterState from '../models/ModelTableWithRouterState';
import { SearchFormHorizontal } from '../search/SearchFormHorizontal';
import { Page, PageNav } from '../shared/Page';
import { CaseDetailsSearchFields } from './CaseDetailsSearchFields';
import { WithPermission } from '../auth/AccessConditionals';
import { SelectFromListForUrl } from '../search/SelectFromList';
import { QueryParameter } from '../models/QueryParameter';
import { InputSize } from '../search/InputSize';
import { CheckboxForUrl } from '../search/CheckboxForUrl';
import AddCaseFlowModal from './AddCaseFlowModal';
import { SelectProfileForUrl } from '../search/SelectCreatorProfiles';
import * as StatusUtils from '../utils/Status';
import { useAppConfiguration } from '../shared/contexts/AppConfiguration';
import {
  latestCaseConfiguration,
  caseOrTaskMessage,
  caseStatusesByCaseVersion,
  UiMessageKey
} from '../shared/types';
import { useLocation } from 'react-router-dom';
import { useDynamicDocumentTitle } from '../shared/hooks/DocumentTitleHooks';
import {
  constructSearchAdditionalQueryParams,
  constructSearchDisplayColumns
} from './SearchUtils';

const CaseSearchDetailsPage = (props) => {
  const location = useLocation();
  const appConfig = useAppConfiguration();
  const [showAddCaseModal, setShowAddCaseModal] = useState(false);
  const [timestamp, setTimestamp] = useState(new Date().getTime());
  const [caseStatusValues, setCaseStatusValues] = useState(undefined);
  const [caseStatusLabels, setCaseStatusLabels] = useState(undefined);
  const [columns, setDisplayColumns] = useState([]);
  const [caseConfig, setCaseConfig] = useState({});
  const [caseSearchFields, setCaseSearchFields] = useState(undefined);
  const [tableQueryParams, setTableQueryParams] = useState();

  // attempt to grab hold of a case configuration, and if present use this to obtain configuration relating
  // to which columns from within the schema we display in the search results table. If we don't have a valid
  // case configuration, we don't show any case-schema specific columns
  useEffect(() => {
    const caseRef = new URLSearchParams(location.search).get('caseRef');
    if (caseRef && appConfig.loaded) {
      const caseConfig = latestCaseConfiguration(appConfig, caseRef);
      const statuses = caseStatusesByCaseVersion(
        appConfig,
        caseConfig.ref,
        caseConfig.version
      );
      if (caseConfig) {
        setCaseConfig(caseConfig);
        setCaseStatusValues(
          statuses.map((status) => `${status.id}|${status.label}`)
        );
        setCaseStatusLabels(
          statuses.map((status) => StatusUtils.sanitisedStatusLabel(status))
        );
        setDisplayColumns(constructSearchDisplayColumns(caseConfig));
        const searchFields = new CaseDetailsSearchFields(
          caseConfig.form.schema,
          caseConfig.form.uiSchema
        );
        setCaseSearchFields(searchFields);
        setTableQueryParams(
          constructSearchAdditionalQueryParams(
            appConfig,
            caseConfig,
            searchFields,
            location,
            timestamp
          )
        );
      }
    } else {
      setDisplayColumns(constructSearchDisplayColumns(undefined));
    }
  }, [location, appConfig, timestamp]);

  useDynamicDocumentTitle(() => {
    return `Search ${caseOrTaskMessage(caseConfig, UiMessageKey.TitlePlural)}`;
  });

  function openAddCaseModal() {
    setShowAddCaseModal(true);
  }

  function onAddCaseModalClose(value) {
    if (value) setTimestamp(new Date().getTime());
    setShowAddCaseModal(false);
  }

  return (
    <Page>
      {caseConfig && (
        <PageNav
          title={`Search ${caseOrTaskMessage(
            caseConfig,
            UiMessageKey.TitlePlural
          )}`}
        >
          <span className="mx-auto" />
          <WithPermission name="case:create">
            <button
              onClick={() => openAddCaseModal()}
              className="btn btn-sm btn-outline-secondary"
            >
              {`Add ${caseOrTaskMessage(
                caseConfig,
                UiMessageKey.TitleSingular
              )}`}
            </button>
          </WithPermission>
        </PageNav>
      )}
      {caseSearchFields && (
        <div className="mb-4">
          <SearchFormHorizontal>
            <SelectProfileForUrl />
            <SelectFromListForUrl
              key={QueryParameter.caseStatus.key}
              size={InputSize.sm}
              className="narrow mr-sm-3 mb-1"
              labels={caseStatusLabels}
              values={caseStatusValues}
              addEmptyValue={true}
              parameterKey={QueryParameter.caseStatus.key}
              parameterLabel={QueryParameter.caseStatus.label}
            />
            <CheckboxForUrl
              parameterKey={QueryParameter.dueForReview.key}
              parameterLabel={QueryParameter.dueForReview.label}
            />
            {caseSearchFields.getFormFieldsForUrl()}
          </SearchFormHorizontal>
        </div>
      )}
      {columns && tableQueryParams && (
        <ModelTableWithRouterState
          caption={`Search ${caseOrTaskMessage(
            caseConfig,
            UiMessageKey.TitlePlural
          )}`}
          apiEndpoint={'/api/case'}
          notFoundMessage={`No ${caseOrTaskMessage(
            caseConfig,
            UiMessageKey.TitlePlural
          )} found`}
          additionalQueryParams={tableQueryParams}
          columns={columns}
          itemHref={(item) => generatePath(`/cases/show/${item.id}`)}
          canExportData={true}
          {...props}
        />
      )}

      {appConfig.loaded && caseConfig && showAddCaseModal && (
        <AddCaseFlowModal
          modalTitle={`Add ${caseOrTaskMessage(
            caseConfig,
            UiMessageKey.TitleSingular
          )}`}
          onModalClose={onAddCaseModalClose}
          caseRef={caseConfig.ref}
          caseVersion={caseConfig.version}
          appConfig={appConfig}
        />
      )}
    </Page>
  );
};

export default CaseSearchDetailsPage;
